@import "/styles/variables.scss";
@import "/styles/font-bases.scss";

.card {
  margin-bottom: 32px;
  width: 31.74%;
  margin-right: 2.34%;

  &:nth-child(3n + 3) {
    margin-right: 0;
  }
}

.wrapper {
  padding: 28px 32px 32px;
}

.label {
  @extend %app-subtitle;

  color: $app-black;
  margin-bottom: 20px;
}

.item {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 900px) {
  .card {
    width: 48.5%;
    margin-right: 3%;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(3) {
      margin-right: 3%;
    }
  }

  .wrapper {
    padding: 24px 20px;
  }
}

@media only screen and (max-width: 478px) {
  .card {
    width: 100%;
    margin-right: 0;

    &:nth-child(3) {
      margin-right: 0;
    }
  }
}
