@import "/styles/variables.scss";
@import "/styles/font-bases.scss";

.card {
  margin-bottom: 32px;
  width: 31.74%;
  margin-right: 2.34%;

  &:nth-child(3n + 3) {
    margin-right: 0;
  }
}

.wrapper {
  height: 100%;
  padding: 28px 32px 32px;
  display: flex;
  flex-direction: column;
}

.title {
  @extend %app-subtitle;

  color: $app-black;
}

.meta {
  margin-top: 12px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  .location {
    text-transform: uppercase;
  }

  .status {
    text-transform: uppercase;
    color: $app-blue-grey;
  }
}

.details {
  flex-grow: 1;
  margin-top: auto;

  .item {
    display: flex;
    justify-content: space-between;
  }
}

.actions {
  margin-top: 32px;
  display: flex;

  & > * {
    flex: 1 1 100%;
    text-align: center;
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .card {
    width: 48.5%;
    margin-right: 3%;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(3) {
      margin-right: 3%;
    }

    &:nth-child(4) {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .card {
    width: 100%;
    margin-right: 0;

    &:nth-child(3) {
      margin-right: 3%;
    }
  }
}

@media only screen and (max-width: 900px) {
  .wrapper {
    padding: 24px 20px;
  }
}
