@import "/styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;

  &.link {
    user-select: none;
  }

  &.shadow {
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  }
}

.imageLink {
  user-select: none;

  &:hover {
    .imageWrapper span {
      transform: scale(1.08);
    }
  }
}

.imageWrapper {
  position: relative;
  background-color: #ddd;
  overflow: hidden;
  transition: opacity 0.3s ease;

  &.blur > :first-child {
    filter: blur(8px);
  }

  span {
    transition: transform 0.5s ease-in-out;
  }
}

.wrapper {
  flex-grow: 1;
  transition: opacity 0.3s ease;
}

.container.link:hover {
  .imageWrapper,
  .wrapper {
    opacity: 0.8;
  }
}

@media only screen and (max-width: 900px) {
  .card {
    width: auto;
  }
}
