@import "/styles/variables.scss";

.cardsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  & > * {
    width: 32%;
  }

  &.singleRow {
    & > * {
      flex: 1 1 0;
      margin-right: 32px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .cardsList {
    margin-top: 40px;

    &.singleRow {
      margin-top: 0;
    }

    & > * {
      width: 48%;
      margin-bottom: 32px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .cardsList {
    & > * {
      width: 100%;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &.singleRow {
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 600px) {
  .cardsList {
    margin-top: 20px;
  }
}
