@import "/styles/variables.scss";
@import "/styles/font-bases.scss";

.wrapper {
  height: 100%;
  padding: 28px 32px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  @extend %app-subtitle;

  color: $app-black;
  text-align: center;
}

.link {
  margin-top: 32px;
}
